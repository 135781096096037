:root {
  --main-color: #333;
  --primary-color: #acacac;
  --icon-color: #525252;
}

i {
  width: 2rem;
  height: 2rem;
}

.fa,
.fas {
  font-weight: 900;
  display: grid;
  place-items: center;
  font-size: 1rem;
  cursor: pointer;
}

.continue-shopping {
  display: flex;
  height: inherit;
  align-items: center;
}

.continue-shopping .arrow-icon {
  width: 2.2rem;
  height: 2.2rem;
}

.continue-shopping h3 {
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--main-color);
  margin-left: 1.5rem;
  font-weight: 400;
}

.cart-icon {
  display: flex;
  text-align: end;
  margin-right: 2rem;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}

.cart-icon img {
  width: 5rem;
  height: 5rem;
  color: #2f80ed;
}

/* .cart-icon p {
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: -1.2rem;
  top: 0.9rem;
  border-radius: 50%;
  background: #99cbf7;
  color: #fff;
  box-sizing: border-box;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
} */
.cart-icon p {
  position: absolute;
  /* opacity: 0.4; */
  width: 2rem;
  height: 2rem;
  right: -2rem;
  top: -13px;
  border-radius: 50%;
  /* background: ivory; */
  color: #fff;
  box-sizing: border-box;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
}
.main-cart-section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  text-transform: capitalize;
  color: rgba(41, 41, 41, 1);
  margin-bottom: 1rem;
}

.main-cart-section .total-items {
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  text-transform: capitalize;
  color: var(--main-color);
  margin-bottom: 5rem;
}

.total-items-count {
  font-weight: bold;
  color: var(--main-color);
}

/* cart main div start  */

.main-cart-section .cart-items {
  width: 100%;
  height: 62rem;
  background: linear-gradient(
    103.49deg,
    #ffffff -28.13%,
    rgba(242, 247, 255, 0.5) 116.84%
  );
  box-shadow: rgba(0, 0, 0, 0.08) 0rem 0.4rem 1.2rem;
  border-radius: 2rem;
  margin: auto;
  margin: auto;
  display: grid;
  place-items: center;
}

.cart-items hr {
  margin: 2rem auto;
  overflow-y: scroll;
}

.cart-items-container {
  width: 90%;
  height: 56rem;
}

.items-info {
  width: 100%;
  height: 11rem;
  /* background-color: lavender; */
  margin: auto;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.centered-container {
  display: flex;
  justify-content: center; /* Horizontally center-align content */
  align-items: center; /* Vertically center-align content */
}
.items-info .product-img img {
  width: 16rem;
  height: 11rem;
  filter: drop-shadow(0rem 0.4rem 1rem #f1f7ff);
  border-radius: 1rem;
}

.items-info .title {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.items-info .title h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--main-color);
}

.items-info .title p {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-align: left;
  color: var(--primary-color);
}

.add-minus-quantity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-minus-quantity button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.add-minus-quantity input {
  width: 6rem;
  height: 3rem;
  border: 0.141rem solid var(--primary-color);
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  text-align: center;
  text-transform: capitalize;
  color: var(--primary-color);
  margin: 0 1rem;
  border-radius: 0.5rem;
  outline: none;
  background-color: transparent;
}

.add-minus-quantity input:focus {
  outline: none;
}

.price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.price h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: var(--main-color);
}

.remove-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5rem;
}

.remove-item button {
  border: none;
  background-color: transparent;
}

.card-total {
  width: 95%;
  margin-top: 4rem;
  text-align: right;
}

.card-total h3 {
  font-style: 200;
  font-size: 2.58rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: #606166;
}

.card-total h3 span {
  font-style: normal;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: #000000;
  margin-left: 1rem;
}

.card-total button {
  border: none;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  color: #fff;
  background-color: #349bf3;
  text-transform: uppercase;
  margin-top: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  letter-spacing: 1px;
}

/* hover effects  */
.fa-trash-alt {
  color: #ed4848;
}

.fa-plus:hover {
  color: #42c157;
}

.fa-minus:hover {
  color: #ffb800;
}

.fa-plus,
.fa-minus {
  color: var(--icon-color);
}

.card-total button:hover {
  background-color: #0077dc;
}

.card-total .clear-cart {
  margin-left: 3rem;
  background-color: rgb(209, 61, 61);
}

.card-total .clear-cart:hover {
  background-color: rgb(197, 5, 5);
}

/* responsive media queries  */

@media (max-width: 968px) {
  html {
    /* font-size: 50%; */
    font-size: 100%;
  }
}

@media (max-width: 768px) {
  .continue-shopping h3 {
    margin-left: 0;
    font-size: 1.85rem;
  }

  .items-info {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title,
  .add-minus-quantity,
  .price,
  .remove-item {
    padding-left: 2rem;
  }

  .items-info .product-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .add-minus-quantity {
    margin: 2rem 0 2rem 0;
    justify-content: flex-start;
  }

  .price {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .price h3::before {
    content: "Price: ";
  }

  .remove-item {
    justify-content: flex-start;
  }

  .card-total {
    margin-bottom: 2rem;
    text-align: center;
  }

  .card-total button {
    margin-bottom: 3rem;
  }
}
